.transaction-screen-container-pc {
    /*width: 600px;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*padding: 10px;*/
    /*border-radius: 8px;*/
}

.transaction-screen-pc {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.block-pc {
    padding: 10px;
}

.block0-pc {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.block1-pc {
    flex: 1;
    display: flex;
    align-items: flex-start;
}

.block2-pc {
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
}

.block3-pc {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding: 0% 20%;
}
