.registerScreenContainer-mb {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: column;
}

.registerScreenImg-mb {
    object-fit: contain;
    height: auto;
    width: 60%;
}

.registerScreenForm-mb {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.registerScreenTitle-mb {
    font-size: 34px;
    font-weight: 900;
    color: var(--color4);
    margin-bottom: 0px;
}

.registerScreenIntro-mb {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: 100;
    color: var(--color1);
}

.registerScreenLoginContainer-mb {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.registerScreenLoginText-mb {
    color: var(--color1);
    margin-right: 10px;
    font-size: 16px;
    font-weight: 100;
}

.registerScreenLoginButton-mb {
    color: var(--color4);
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
}