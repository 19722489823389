.buttonOne {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: var(--color6);
    background-color: var(--color4);
    transition: transform 0.2s, box-shadow 0.2s;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 5px 10px var(--color5);
}

.buttonOne:hover {
    transform: scale(1.05);
}
