.transaction-list-container {
    width: 100%;
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 20px;
    max-height: 250px;
    overflow-y: auto;
}

.transaction-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    background: var(--color2);
    border-radius: 4px;
    transition: background 0.3s;
}

.transaction-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.transaction-icon {
    font-size: 24px;
}

.transaction-type {
    font-size: 16px;
    font-weight: 500;
    color: var(--color0);
}

.transaction-category {
    display: block;
    font-size: 14px;
    margin-top: 2px;
}

.category-income {
    color: green;
}

.category-expense {
    color: red;
}

.transaction-right {
    text-align: right;
}

.transaction-amount {
    font-size: 16px;
    font-weight: bold;
    color: var(--color4);
    display: flex;
}

.transaction-date {
    font-size: 12px;
    color: var(--color1);
    margin-top: 2px;
}

.transaction-delete {
    font-size: 20px;
    color: var(--color1);
    cursor: pointer;
    margin-left: 15px;
    transition: color 0.3s, transform 0.2s ease;
}

.transaction-delete:hover {
    color: red;
    transform: scale(1.1);
}
