.bottom-menu-mb {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background-color: var(--color2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    z-index: 100;
    /*box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);*/
}

.menu-item-mb {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    color: var(--color0);
    transition: color 0.3s, transform 0.2s;
}

.menu-item-mb .bottom-icon-mb {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
    color: var(--color0);
    transition: color 0.3s;
}

.menu-item-mb.active {
    color: var(--color4);
}

.menu-item-mb.active .bottom-icon-mb {
    color: var(--color4);
}

.menu-item-mb:hover {
    color: var(--color4);
    /*transform: scale(1.5);*/
}

.menu-item-mb:hover .bottom-icon-mb {
    color: var(--color4);
}

.menu-center-mb {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color4);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 15px var(--color4);
    transition: transform 0.2s, bottom 0.3s;
}

.menu-center-mb.active {
    bottom: 15px;
    /*transform: translateX(-50%) scale(1.5);*/
}

.menu-center-mb:hover {
    /*transform: translateX(-50%) scale(1.5);*/
}

.menu-center-mb .center-icon-mb {
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
}
