.transaction-screen-container-mb {

}

.input-amount-container-mb {
    width: 100%;
    margin: 20px 0px;
    background-color: var(--color2);
}

.input-amount-mb {
    width: 50%;
    justify-self: center;
}

.input-amount-button-mb {
    padding: 10px;
}