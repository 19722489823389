.top-menu-container-pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    background-color: var(--color2);
    /*border-bottom: 1px solid var(--color7);*/
}

.top-menu-left-section-pc,
.top-menu-right-section-pc {
    display: flex;
    align-items: center;
}

.top-menu-app-image-pc {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 8px;
}

.top-menu-app-name-pc {
    font-size: 16px;
    font-weight: 500;
    color: var(--color4);
}

.top-menu-user-image-pc {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
}

.top-menu-user-name-pc {
    font-size: 16px;
    font-weight: 500;
    color: var(--color0);
}

