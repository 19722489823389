.transaction-type-container {
    width: 100%;
    height: 100%;
    background: var(--color2);
    padding: 10px;
    box-sizing: border-box;
    overflow-x: auto;
}

.transaction-type-list-wrapper {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    width: max-content;
}

.transaction-type-list {
    display: grid;
    grid-template-rows: repeat(3, minmax(115px, 1fr));
    grid-auto-flow: column;
    gap: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.transaction-type-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    width: 129px;
    height: 114px;
    background-color: var(--color6);
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s ease-in-out;
    scroll-snap-align: center;
}

.transaction-type-item:hover {
    background: var(--color5);
}

.transaction-type-item.selected {
    background: var(--color4);
}

.transaction-type-item.selected .name,
.transaction-type-item.selected .description,
.transaction-type-item.selected .icon {
    color: #ffffff;
}

.transaction-type-item:hover .name,
.transaction-type-item:hover .description,
.transaction-type-item:hover .icon {
    color: #ffffff;
}

.transaction-type-item .icon {
    font-size: 24px;
    margin-bottom: 5px;
}

.transaction-type-item .name {
    font-size: 14px;
    font-weight: bold;
    color: var(--color3);
    margin-bottom: 4px;
}

.transaction-type-item .description {
    font-size: 12px;
    color: var(--color1);
    line-height: 1.4;
}
