.loginScreenContainer-pc {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row;
}

.loginScreenImg-pc {
    object-fit: contain;
    height: auto;
    width: 20%;
}

.loginScreenForm-pc {
    display: flex;
    flex-direction: column;
    width: 20%;
}

.loginScreenTitle-pc {
    font-size: 34px;
    font-weight: 900;
    color: var(--color4);
    margin-bottom: 0px;
}

.loginScreenIntro-pc {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: 100;
    color: var(--color1);
}

.loginScreenRegisterContainer-pc {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.loginScreenRegisterText-pc {
    color: var(--color1);
    margin-right: 10px;
    font-size: 16px;
    font-weight: 100;
}

.loginScreenRegisterButton-pc {
    color: var(--color4);
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
}