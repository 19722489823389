.loginScreenContainer-mb {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: column;
}

.loginScreenImg-mb {
    object-fit: contain;
    height: auto;
    width: 60%;
}

.loginScreenForm-mb {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.loginScreenTitle-mb {
    font-size: 34px;
    font-weight: 900;
    color: var(--color4);
    margin-bottom: 0px;
}

.loginScreenIntro-mb {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: 100;
    color: var(--color1);
}

.loginScreenRegisterContainer-mb {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.loginScreenRegisterText-mb {
    color: var(--color1);
    margin-right: 10px;
    font-size: 16px;
    font-weight: 100;
}

.loginScreenRegisterButton-mb {
    color: var(--color4);
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
}