.top-menu-container-mb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 16px;
    /*background-color: white;*/
}

.top-menu-left-section-mb {
    display: flex;
    align-items: center;
}

.top-menu-user-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
}

.top-menu-user-name {
    font-size: 16px;
    font-weight: 500;
    color: var(--color0);
}

.top-menu-icon-mb {
    font-size: 24px;
    color: var(--color0);
    cursor: pointer;
}
