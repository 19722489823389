.profile-items-container-mb {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.profile-item-mb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.profile-item-mb.active {
    background-color: var(--color4);
    color: var(--color6);
}

.profile-item-mb.active .menu-item-icon-mb {
    color: var(--color6);
}

.profile-item-mb.active .menu-item-text-mb {
    color: var(--color6);
}

.menu-item-left-mb {
    display: flex;
    align-items: center;
}

.menu-item-icon-mb {
    font-size: 20px;
    color: var(--color0);
}

.menu-item-text-mb {
    font-size: 16px;
    font-weight: 500;
    color: var(--color0);
    margin-left: 15px;
}

.menu-item-info-icon-mb {
    font-size: 20px;
    color: var(--color0);
}
