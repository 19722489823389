.inputTextContainer {
    display: flex;
    align-items: center;
    background-color: var(--color2);
    border-radius: 8px;
    padding: 12px 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.inputTextIcon {
    color: var(--color1);
    margin-right: 10px;
    font-size: 20px;
    padding: 0px 10px;
}

.inputTextInput {
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    color: var(--color0);
    width: 100%;
}

.inputTextInput::placeholder {
    color: var(--color1);
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px var(--color2) inset;
    -webkit-text-fill-color: var(--color0);
    border: none;
}
