.homeScreenContainer-pc {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--color2);
}

.homeScreenMainContent-pc {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.homeScreenContentArea-pc {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: var(--color6);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.05);
    /*display: flex;*/
    /*justify-content: center;*/
}
