.vertical-menu-pc {
    width: 200px;
    height: 100vh;
    background-color: var(--color2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    padding-left: 20px;
}

.vertical-menu-item-pc {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    margin: 5px 0px;
    width: 80%;
    cursor: pointer;
    color: var(--color0);
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
    text-decoration: none;
}

.vertical-menu-item-pc .vertical-menu-icon-pc {
    font-size: 20px;
    margin-right: 10px;
}

.vertical-menu-item-pc span {
    font-size: 16px;
    font-weight: 500;
}

.vertical-menu-item-pc.active {
    background-color: var(--color4);
    color: var(--color6);
}

.vertical-menu-item-pc.active .vertical-menu-icon-pc {
    color: var(--color6);
}

.vertical-menu-item-pc:hover {
    background-color: var(--color5);
    color: var(--color6);
}
