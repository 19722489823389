.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: var(--color6);
    padding: 20px;
    border-radius: 35px;
    width: 80%;
    text-align: center;
    box-shadow: 0px 0px 50px var(--color8);
}

@media only screen and (min-width: 1024px) {
    .modalContent {
        width: 20%;
    }
}

.modalTitle {
    font-size: 34px;
    font-weight: 900;
    color: var(--color3);
    margin-top: 0px;
}

.modalMessage {
    color: var(--color1);
}

.modalButton {
    margin-top: 20px;
    background-color: var(--color4);
    color: var(--color6);
    border: none;
    padding: 15px;
    cursor: pointer;
    border-radius: 20px;
    width: 90%;
}

.modalButton:hover {
    background-color: var(--color4);
}