.balance-card-pc {
    background: linear-gradient(135deg, var(--color4), var(--color9));
    color: #ffffff;
    padding: 50px 20px;
    border-radius: 24px;
    width: 300px;
    box-shadow: 0px 5px 20px var(--color9);
    font-family: "Poppins", sans-serif;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.balance-card-pc .background-shape-1-pc {
    position: absolute;
    width: 180px;
    height: 180px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    top: -30px;
    left: -30px;
    z-index: -1;
}

.balance-card-pc .background-shape-2-pc {
    position: absolute;
    width: 250px;
    height: 250px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 50%;
    bottom: -40px;
    right: -40px;
    z-index: -1;
}

.balance-card-pc .background-shape-3-pc {
    position: absolute;
    width: 300px;
    height: 100px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50px;
    bottom: 10%;
    left: 10%;
    transform: rotate(-10deg);
    z-index: -1;
}

.balance-card-content-pc {
    z-index: 1;
}

.card-title-pc {
    font-size: 16px;
    font-weight: 500;
    color: #a3e4db;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.balance-amount-pc h1 {
    font-size: 48px;
    font-weight: bold;
    margin: 5px 0;
    color: #ffffff;
}

.decimal-pc {
    font-size: 32px;
    font-weight: 300;
    color: #d2f6ee;
}





