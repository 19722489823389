.switcher-container-mb {
    display: flex;
    position: relative;
    background-color: var(--color2);
    width: 100%;
    margin: auto;
}

.switcher-tab-mb {
    flex: 1;
    padding: 15px 0px;
    background: none;
    border: none;
    text-align: center;
    font-size: 16px;
    color: var(--color1);
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.switcher-tab-mb.active {
    color: var(--color6);
    z-index: 2;
}

.highlight-mb {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 50%;
    background-color: var(--color4);
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.highlight-mb.Income {
    transform: translateX(0);
}

.highlight-mb.Expense {
    transform: translateX(100%);
}
